import type { BeforeRequestHook } from 'ky';
import { extractInterfaceFromRoute } from '../../../extractInterfaceFromRoute';
import { HTTP_HEADERS } from '../../../../auth/const';
import { isServer } from '../../utils';
import type { HookGetterArgs } from '../types';
import { IS_PUBLIC_INTERFACE } from '../../../consts/interface';
import { requestWithNewUrl } from '../../../request';
import isMobile from '../../../native/isMobile';

const makePublicRequest = async (request: Request): Promise<Request> => {
  const newUrl = new URL(request.url);

  if (newUrl.pathname.startsWith('/api/')) {
    newUrl.pathname = newUrl.pathname.replace('/api/', '/api-endpoint/external/');
  }

  return requestWithNewUrl(request, newUrl.toString());
};

export const getInterfaceHeaders = ({ fetchArgs: { url } }: HookGetterArgs): BeforeRequestHook => {
  const interfaceHeadersHook: BeforeRequestHook = async (req) => {
    let request = req;
    if (isServer() && url.startsWith('/')) {
      const { headers: nextHeaders } = await import('next/headers');
      const header = nextHeaders();

      if (header.get(HTTP_HEADERS.PUBLIC)) {
        request = await makePublicRequest(request);
      }

      const interfaceId = extractInterfaceFromRoute(header.get('pathname') ?? '');
      if (interfaceId) {
        request.headers.set(HTTP_HEADERS.AppId, interfaceId);
      }
    }

    if (!isServer() && !isMobile()) {
      const interfaceId = extractInterfaceFromRoute(window.location.pathname);

      if (IS_PUBLIC_INTERFACE) {
        request = await makePublicRequest(request);
      }

      if (interfaceId) {
        request.headers.set(HTTP_HEADERS.AppId, interfaceId);
      }
    }

    return request;
  };
  return interfaceHeadersHook;
};
