import { useMemo } from 'react';

function StyleOverride({ style }: { style?: string }) {
  const innerHTML = useMemo(
    () => ({
      __html: style || '',
    }),
    [style],
  );
  return <style dangerouslySetInnerHTML={innerHTML} />;
}

export default StyleOverride;
