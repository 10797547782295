// white listing these routes as these are to executed in unifyapps context
const whitelistedRoutes = [
  'interfaces/custom',
  'interfaces/standard',
  'interfaces/create',
  'interfaces/edit',
];

export const extractInterfaceFromRoute = (route: string): string | null | undefined => {
  const params = route.split('/');
  const indexOfInterfaces = params.indexOf('interfaces');
  if (indexOfInterfaces === -1 || whitelistedRoutes.some((it) => route.includes(it))) {
    return null;
  }
  return params[indexOfInterfaces + 1];
};
