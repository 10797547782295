export type InputStateProps = {
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};
export type InputState = 'default' | 'error' | 'disabled' | 'readonly';

export function getInputState(props: InputStateProps): InputState {
  if (props.error) {
    return 'error';
  }
  if (props.disabled) {
    return 'disabled';
  }
  if (props.readOnly) {
    return 'readonly';
  }
  return 'default';
}
