import { useMemo, useState } from 'react';

// Reference: https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-boolean.ts
type InitialState = boolean | (() => boolean);

function useToggle(initialState: InitialState = false) {
  const [value, setValue] = useState(initialState);
  const callbacks = useMemo(
    () => ({
      on: () => setValue(true),
      off: () => setValue(false),
      toggle: () => setValue((prev) => !prev),
    }),
    [],
  );
  return [value, callbacks] as const;
}

export default useToggle;
