import { Chip as BaseChip } from '@mui/joy';
import type { ComponentType, NamedExoticComponent } from 'react';
import { memo } from 'react';
import { Typography } from '../Typography';
import type { IconComponentType } from '../../types/index';
import { Box } from '../Box';
import type { ChipProps } from './types';
import { chipVariants } from './styles';

function Chip(props: ChipProps) {
  const {
    className,
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorComponent: EndDecoratorComponent,
    endDecoratorNode,
    label,
    onClick,
    slotProps,
    variant = 'outlined',
  } = props;

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent className="h-4 w-4" />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent className="h-4 w-4" />
  ) : (
    endDecoratorNode
  );

  const chipClassName = chipVariants({
    className: ['isolate', className],
    variant: 'outlined',
  });

  return (
    <BaseChip
      className={chipClassName}
      endDecorator={endDecorator}
      onClick={onClick}
      slotProps={{
        endDecorator: { className: 'pointer-events-auto' },
        action: { className: '!bg-transparent' },
        ...slotProps,
      }}
      startDecorator={startDecorator}
      variant={variant}
    >
      <Typography
        as="p"
        className="truncate"
        color="text-secondary"
        variant="text-xs"
        weight="medium"
      >
        {label}
      </Typography>
    </BaseChip>
  );
}

const ChipMemo = memo(Chip) as unknown as NamedExoticComponent<ChipProps> & {
  Icon: ComponentType<{
    Icon: IconComponentType;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  }>;
  Count: ComponentType<{ count: string }>;
};

ChipMemo.Icon = function ChipIcon({ Icon, onClick }) {
  return (
    <Box
      className="hover:bg-primary_hover rounded-xxs p-xxs text-fg-quaternary hover:text-fg-quaternary cursor-pointer"
      onClick={onClick}
      role="button"
    >
      <Icon className="h-3 w-3" />
    </Box>
  );
};

ChipMemo.Count = function ChipCount({ count }) {
  return (
    <Box className="bg-tertiary flex h-4 w-4 items-center justify-center rounded-full">
      <Typography color="text-secondary" variant="text-xs" weight="medium">
        {count}
      </Typography>
    </Box>
  );
};

export default ChipMemo;
