import { useMemo, type ReactNode } from 'react';
import { ErrorBoundary as BaseErrorBoundary, type ErrorBoundaryProps } from '@sentry/react';
import { OnErrorContext, useOnErrorContext } from './OnErrorContext';

type Props = ErrorBoundaryProps & {
  children: ReactNode;
  onError?: (error: unknown) => void;
};

export function ErrorBoundary({ children, onError, ...restProps }: Props) {
  const { onError: parentOnError } = useOnErrorContext();

  const value = useMemo(
    () => ({
      onError: (error: unknown) => {
        onError?.(error);
        parentOnError?.(error);
      },
    }),
    [onError, parentOnError],
  );

  return (
    <BaseErrorBoundary {...restProps} onError={value.onError}>
      <OnErrorContext.Provider value={value}>{children}</OnErrorContext.Provider>
    </BaseErrorBoundary>
  );
}
