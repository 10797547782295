import { cva } from 'class-variance-authority';

export const autocompleteRootVariants = cva(['flex flex-row items-center justify-center text-sm'], {
  variants: {
    size: { sm: 'min-h-7', md: 'min-h-9' },
    multiple: { false: '', true: '' },
    focused: { false: '', true: 'ring-brand border-brand' },
    open: { false: '', true: 'ring-brand border-brand' },
    error: { false: '', true: 'border-error' },
    disabled: { false: '', true: '' }, // override joy's disabled styles
    readOnly: { false: '', true: '' },
  },
  compoundVariants: [
    // default bg
    { disabled: false, readOnly: false, className: 'bg-primary' },
    // size
    { multiple: true, size: 'md', className: 'py-sm px-lg' },
    { multiple: true, size: 'sm', className: 'py-xxs px-md' },
    { multiple: false, size: 'md', className: 'py-md px-lg h-9' },
    { multiple: false, size: 'sm', className: 'py-sm px-md h-7' },
    // focus rings
    { error: true, focused: true, className: 'ring-error' },
    { error: true, open: true, className: 'ring-error' },
    // default border
    {
      error: false,
      disabled: false,
      readOnly: false,
      open: false,
      focused: false,
      className: 'border-primary',
    },
  ],
});

export const clearIndicatorVariants = cva(
  ['p-xxs min-h-0 min-w-0 bg-transparent hover:bg-secondary_hover rounded-xs'],
  {
    variants: {
      size: { sm: 'size-5', md: 'size-5' },
    },
  },
);

export const autocompleteListboxVariants = cva(
  [
    /**
     * Do not add margin to the listbox, it breaks the overflow detection in Poppper.js.
     * Instead use offset modifier in Popper.js to position the listbox relative to the reference element.
     * If margin is used, the listbox will render that many pixels away and even though it would be overflowing, Popper.js will not be able detect it until it is overflowing by more than that many pixels.
     * As only size is used to detect overflow. This will lead to scrollbar appearing on the body as popper is placed in body.
     */
    'flex flex-col z-listbox border border-secondary',
    'bg-primary border-secondary rounded-md shadow-lg',
    'data-[popper-reference-hidden]:hidden', // hide popper when reference is not visible
  ],
  {
    variants: {
      size: {
        sm: 'px-0 py-xs',
        md: 'px-0 py-xs',
      },
    },
    compoundVariants: [],
  },
);

export const autocompleteOptionVariants = cva(
  [
    'flex flex-row gap-md items-center justify-between rounded-sm text-sm !font-normal',
    // joy has so many selectors for background T_T, selected x hover x active. using important
    // using mui specific classname because list items cannot be focused via tab key.
    '!text-primary [&.Mui-focusVisible]:ring-brand',
    'mx-sm my-xxs px-md py-sm gap-md min-h-7',
  ],
  {
    variants: {
      size: {
        sm: 'py-sm',
        md: 'py-md',
      },
      selected: {
        true: '!bg-[var(--input-menu-selected-bg)] hover:!bg-[var(--input-menu-selected-bg-hover)]',
        false: 'hover:!bg-[var(--input-menu-default-bg-hover)]',
      },
    },
    compoundVariants: [],
  },
);

export const inputVariants = cva('', {
  variants: {
    size: {
      sm: 'h-4 text-xs',
      md: 'text-sm',
    },
  },
});

export const dropdownIndicatorVariants = cva('', {
  variants: {
    disabled: {
      true: 'text-fg-disabled',
      false: 'text-fg-quaternary',
    },
    size: {
      sm: 'h-4 w-4',
      md: '',
    },
  },
});
