import './button.css';
import { default as BaseButton } from '@mui/joy/Button';
import { forwardRef } from 'react';
import type { ButtonProps } from './types';
import { defaultButtonClasses, iconSizeVariants } from './styles';

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const {
    variant,
    color,
    size,
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorComponent: EndDecoratorComponent,
    endDecoratorNode,
    className,
    loading,
    ...rest
  } = props;

  const iconClassName = iconSizeVariants({ size });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent className={iconClassName} />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent className={iconClassName} />
  ) : (
    endDecoratorNode
  );

  return (
    <BaseButton
      ref={ref}
      {...rest}
      className={defaultButtonClasses(color, variant, size, className)}
      data-color={color}
      data-size={size}
      data-variant={variant}
      endDecorator={endDecorator}
      loading={loading}
      startDecorator={startDecorator}
    />
  );
});

Button.displayName = 'Button';

export default Button;
