// ref: https://stackoverflow.com/a/34641566
export const requestWithNewUrl = async (request: Request, newUrl: string): Promise<Request> => {
  return new Request(newUrl, {
    method: request.method,
    headers: request.headers,
    body: request.body ? await request.blob() : undefined,
    referrer: request.referrer,
    referrerPolicy: request.referrerPolicy,
    mode: request.mode,
    credentials: request.credentials,
    cache: request.cache,
    redirect: request.redirect,
    integrity: request.integrity,
  });
};
