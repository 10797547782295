import * as React from 'react';
import type { SVGProps } from 'react';
const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M4.625 6.667A6.04 6.04 0 0 1 10.667.625H24c.154 0 .302.061.412.17l10.793 10.793c.109.11.17.258.17.412v21.333a6.04 6.04 0 0 1-6.042 6.042H10.667a6.04 6.04 0 0 1-6.042-6.042z"
    />
    <path
      stroke="#5C37EB"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M25.25 30.75h.758c.728 0 1.092 0 1.293-.152a.75.75 0 0 0 .296-.553c.015-.252-.187-.555-.59-1.16l-2.259-3.387c-.334-.501-.5-.751-.71-.839a.75.75 0 0 0-.575 0c-.21.088-.378.338-.712.839l-.558.837m3.057 4.415-5.764-8.325c-.331-.479-.497-.718-.704-.802a.75.75 0 0 0-.565 0c-.207.084-.372.323-.704.802l-4.46 6.442c-.422.61-.633.915-.62 1.168a.75.75 0 0 0 .293.56c.201.155.572.155 1.314.155zm1.5-11.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0"
    />
  </svg>
);
export default SvgImage;
