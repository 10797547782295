import type { BeforeErrorHook } from 'ky';
import { getResponseData } from '../../utils';
import { UAFetchError } from '../../UAFetchError';

async function handleError({ response, url }: { response: Response; url: string }) {
  console.error('[UAFetchError] Failed to fetch', {
    statusText: response.statusText,
    status: response.status,
    url: response.url,
    response,
  });

  const responseData = await getResponseData(response);

  const error = new UAFetchError({
    message: `[UAFetchError] Failed to fetch ${url} : [${response.status}] ${response.statusText}`,
    status: response.status,
    statusText: response.statusText,
    url,
    payload:
      typeof responseData === 'object'
        ? responseData
        : {
            message: responseData,
          },
  });

  throw error;
}

export const getLogErrorHook = (): BeforeErrorHook => {
  const beforeErrorHook: BeforeErrorHook = async (error) => {
    const { response, request } = error;
    await handleError({ response, url: request.url });
    return error;
  };
  return beforeErrorHook;
};
