import { cva } from 'class-variance-authority';
import { clsx } from 'clsx';
import type { ButtonColors, ButtonProps, ButtonSizes, ButtonVariants } from './types';

export const buttonBaseVariants = cva(
  'flex flex-row items-center justify-center font-semibold shrink-0',
  {
    variants: {
      color: {
        brand: 'focus-visible:ring-brand',
        neutral: 'focus-visible:ring-gray',
        danger: 'focus-visible:ring-error',
      } satisfies Record<ButtonColors, string>,
      variant: {
        // TODO: use border width from component token
        solid: 'border shadow-xs',
        outline: 'border shadow-xs',
        soft: 'border-0',
        ghost: 'border-0 hover:underline',
      } satisfies Record<ButtonVariants, string>,
    },
  },
);

export const buttonSizeVariants = cva('', {
  variants: {
    variant: {
      solid: '',
      outline: '',
      soft: '',
      ghost: '',
    } satisfies Record<ButtonVariants, string>,
    size: {
      xs: 'gap-xxs text-xs',
      sm: 'gap-xs text-sm',
      md: 'gap-xs text-sm',
      lg: 'gap-sm text-md',
    } satisfies Record<ButtonSizes, string>,
  },
  compoundVariants: [
    {
      size: 'xs',
      variant: ['solid', 'outline', 'soft'],
      className: 'max-h-6 min-w-[3.25rem]',
    },
    {
      size: 'sm',
      variant: ['solid', 'outline', 'soft'],
      className: 'max-h-7 min-w-14',
    },
    {
      size: 'md',
      variant: ['solid', 'outline', 'soft'],
      className: 'max-h-9 min-w-[4.5rem]',
    },
    {
      size: 'lg',
      variant: ['solid', 'outline', 'soft'],
      className: 'max-h-12 min-w-24',
    },
    {
      variant: 'ghost',
      className: '!p-0',
    },
  ],
});

export const iconSizeVariants = cva('shrink-0', {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-4',
      md: 'size-4',
      lg: 'size-5',
    } satisfies Record<ButtonSizes, string>,
  },
});

export const BUTTON_ID_CLASSNAME = 'ua-button';
export const defaultButtonClasses = (
  color: ButtonProps['color'],
  variant: ButtonProps['variant'],
  size: ButtonProps['size'],
  className: string | undefined,
) =>
  clsx(
    BUTTON_ID_CLASSNAME,
    buttonBaseVariants({
      color,
      variant,
    }),
    buttonSizeVariants({
      variant,
      size,
    }),
    className,
  );
