import type { BeforeRequestHook } from 'ky';
import { isServer } from '../../utils';
import { HTTP_HEADERS } from '../../../../auth/const';
import type { HookGetterArgs } from '../types';

export const getDeltaMatrixHeaders = (args: HookGetterArgs): BeforeRequestHook => {
  const deltaMatrixHeadersHook: BeforeRequestHook = (request) => {
    if (!isServer() && '__unifyBaseUrl' in window) {
      // @ts-expect-error -- used in external to pass AppId for interfaces
      request.headers.set(HTTP_HEADERS.AppId, window.__unifyAppId as string);
      if ('__unifyTenantDomain' in window && typeof window.__unifyTenantDomain !== 'undefined') {
        request.headers.set(HTTP_HEADERS.TenantDomain, window.__unifyTenantDomain as string);
      }
    }
  };
  return deltaMatrixHeadersHook;
};
