import { HTTP_HEADERS } from '../auth/const';
import { DEFAULT_PROJECT_ID } from './consts/project';

/* eslint-disable prefer-named-capture-group -- needed */
const DETAILS_PATTERN = /^\/p\/([\dA-Za-z]+)\/?.*$/;

export const parseProjectSlug = (
  projectSlug: string,
): {
  projectId: number;
  showProjectLayout: boolean;
  projectSlug: string;
} => {
  // here we are checking if we have to show project left side nav bar or not
  // if the user is outside the project scope, the projectSlug will start with 0

  const showProjectLayout = isPathWithinProject(projectSlug);
  const projectId = Number(projectSlug);

  return {
    projectId,
    showProjectLayout,
    projectSlug,
  };
};

export const isPathWithinProject = (projectSlug?: string) => {
  if (!projectSlug) {
    return false;
  }
  // If the project slug does not not start with 0, it means that the user is inside the project
  return !projectSlug.startsWith('0');
};

export const extractProjectDetailsFromRoute = (
  route: string,
): { projectSlug: string; projectId: number } => {
  const match = DETAILS_PATTERN.exec(route);

  if (match?.[1]) {
    const { projectSlug, projectId } = parseProjectSlug(match[1]);
    return { projectSlug, projectId };
  }

  return { projectSlug: '', projectId: 0 };
};

export const addProjectHeader = (headers: Headers, projectId: string | null) => {
  if (projectId && Number(projectId) !== DEFAULT_PROJECT_ID) {
    headers.set(HTTP_HEADERS.ProjectId, projectId);
  }
};
