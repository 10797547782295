'use client';

import React, { createContext, useContext } from 'react';
import type { DeviceVariantType } from './types';

type DeviceContextType = DeviceVariantType | null;

export const DeviceContext = createContext<DeviceContextType | null>(null);

export const useDeviceContext = () => {
  const context = useContext(DeviceContext);
  if (context === null) {
    // throw new Error('DeviceContext not found'); // The value is not mandatory so not throwing the error
  }
  return context;
};

export function DeviceContextProvider({
  value,
  children,
}: {
  value: DeviceContextType;
  children: React.ReactNode;
}) {
  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
}
