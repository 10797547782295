import useMedia from '../useMedia';
import { DeviceVariantType } from './types';
import { BREAKPOINT_QUERIES } from './const';
import type { UseScreenReturnType } from './types';
import { useDeviceContext } from './context';

export { BREAKPOINT_QUERIES };

/**
 * Use default value from user agent parsed in server, but actual value from media queries
 */

export default function useScreen(): UseScreenReturnType {
  const device = useDeviceContext();
  const screen = useMedia(
    [BREAKPOINT_QUERIES.desktop, BREAKPOINT_QUERIES.tablet, BREAKPOINT_QUERIES.mobile],
    [DeviceVariantType.DESKTOP, DeviceVariantType.TABLET, DeviceVariantType.MOBILE],
    device || DeviceVariantType.DESKTOP,
  );

  const isDesktop = screen === DeviceVariantType.DESKTOP;
  const isTablet = screen === DeviceVariantType.TABLET;
  const isMobile = screen === DeviceVariantType.MOBILE;

  return { screen, isDesktop, isTablet, isMobile };
}

export function useAdaptiveScreen(devices: DeviceVariantType[]) {
  const device = useDeviceContext();
  const screen = useMedia(
    devices.map((d) => BREAKPOINT_QUERIES[d]),
    devices,
    device || DeviceVariantType.DESKTOP,
  );

  return { screen };
}
