import type { JSONSchema7TypeName } from 'json-schema';
import Type01 from '@unifyapps/icons/outline/Type01';
import BracketsEllipses from '@unifyapps/icons/outline/BracketsEllipses';
import Link01 from '@unifyapps/icons/outline/Link01';
import File04 from '@unifyapps/icons/outline/File04';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import Variable from '@unifyapps/icons/outline/Variable';
import DataTypeArray from '@unifyapps/icons/outline/data-types/DataTypeArray';
import DataTypeBoolean from '@unifyapps/icons/outline/data-types/DataTypeBoolean';
import DataTypeInteger from '@unifyapps/icons/outline/data-types/DataTypeInteger';
import DataTypeDecimal from '@unifyapps/icons/outline/data-types/DataTypeDecimal';
import DataTypeTime from '@unifyapps/icons/outline/data-types/DataTypeTime';
import DataTypeObject from '@unifyapps/icons/outline/data-types/DataTypeObject';
import DataTypeText from '@unifyapps/icons/outline/data-types/DataTypeText';
import DataTypeDate from '@unifyapps/icons/outline/data-types/DataTypeDate';
import DataTypeBinary from '@unifyapps/icons/outline/data-types/DataTypeBinary';
import DataTypeTimestamp from '@unifyapps/icons/outline/data-types/DataTypeTimestamp';
import DataTypeDatetime from '@unifyapps/icons/outline/data-types/DataTypeDatetime';
import type { SVGProps } from 'react';

export const JSONSchemaPropertyIcon: Record<
  | JSONSchema7TypeName
  | 'long'
  | 'date'
  | 'date-time'
  | 'file-input'
  | 'file-uploader'
  | 'string,number,integer,boolean'
  | 'binary'
  | 'time'
  | 'timestamp'
  | 'decimal'
  | 'ua-formula'
  | 'other',
  ((props: SVGProps<SVGSVGElement>) => React.JSX.Element) | undefined
> = {
  array: DataTypeArray,
  boolean: DataTypeBoolean,
  integer: DataTypeInteger,
  long: DataTypeInteger,
  number: DataTypeDecimal,
  object: DataTypeObject,
  string: DataTypeText,
  null: DataTypeObject,
  date: DataTypeDate,
  binary: DataTypeBinary,
  other: DataTypeObject,
  decimal: DataTypeDecimal,
  time: DataTypeTime,
  timestamp: DataTypeTimestamp,
  'date-time': DataTypeDatetime,
  'file-input': Link01,
  'file-uploader': File04,
  'string,number,integer,boolean': Type01,
  'ua-formula': Variable,
};

export const JSONSCHEMA_TYPE_ICON_MAP: Record<string, string> = {
  array: 'DataTypeArray',
  boolean: 'DataTypeBoolean',
  integer: 'DataTypeInteger',
  number: 'DataTypeDecimal',
  object: 'DataTypeObject',
  string: 'DataTypeText',
  null: 'DataTypeObject',
  date: 'DataTypeDate',
  binary: 'DataTypeBinary',
  other: 'DataTypeObject',
  decimal: 'DataTypeDecimal',
  timestamp: 'DataTypeTimestamp',
  'date-time': 'DataTypeDatetime',
  'file-input': 'Link01',
  'file-uploader': 'File04',
  'ua-formula': 'SvgVariable',
};

export const DATA_TYPE_ICON_MAP: Record<string, string> = {
  ARRAY: 'BracketsSquare',
  BOOLEAN: 'Settings04',
  INTEGER: 'Integer',
  LONG: 'Integer',
  DOUBLE: 'Double',
  DECIMAL: 'Decimal',
  DATE_TIME: 'SvgCalendarClock',
  DATE: 'Calendar',
  TIMESTAMP: 'SvgCalendarClock',
  TIME: 'Clock',
  ZONED_TIMESTAMP: 'SvgCalendarClock',
  LOCAL_ZONED_TIMESTAMP: 'SvgCalendarClock',
  STRING: 'Type01',
  TEXT: 'Type01',
  KEYWORD: 'Type01',
  OTHER: 'BracketsEllipses',
  UNKNOWN: 'BracketsEllipses',
};

export const getDataTypeIcon = (dataType = 'UNKNOWN') => {
  if (!DATA_TYPE_ICON_MAP[dataType]) {
    return BracketsEllipses;
  }

  return getIconFromRegistry(DATA_TYPE_ICON_MAP[dataType]) || BracketsEllipses;
};
