import { cva } from 'class-variance-authority';
import type { CSSProperties } from 'react';
import type {
  TypographyColors,
  TypographyVariants,
  TypographyWeight,
  TypographyTextAlign,
} from './types';

export const typographyVariants = cva('', {
  variants: {
    variant: {
      'display-2xl': 'display-2xl',
      'display-xl': 'display-xl',
      'display-lg': 'display-lg',
      'display-md': 'display-md',
      'display-sm': 'display-sm',
      'display-xs': 'display-xs',
      'text-xl': 'text-xl',
      'text-lg': 'text-lg',
      'text-md': 'text-md',
      'text-sm': 'text-sm',
      'text-xs': 'text-xs',
      'text-xxs': 'text-xxs',
      'text-xxxs': 'text-xxxs',
      'text-code': 'text-code',
    } satisfies Record<TypographyVariants, string>,
    align: {
      left: 'text-start',
      center: 'text-center',
      right: 'text-end',
      justify: 'text-justify',
    } satisfies Record<TypographyTextAlign, string>,
    weight: {
      regular: 'font-normal',
      medium: 'font-medium',
      'semi-bold': 'font-semibold',
      bold: 'font-bold',
      light: 'font-light',
    } satisfies Record<TypographyWeight, string>,
    color: {
      'text-brand-primary': 'text-brand-primary',
      'text-brand-secondary': 'text-brand-secondary',
      'text-brand-tertiary': 'text-brand-tertiary',
      'text-brand-tertiary_alt': 'text-brand-tertiary_alt',
      'text-disabled': 'text-disabled',
      'text-error-primary': 'text-error-primary',
      'text-error-secondary': 'text-error-secondary',
      'text-placeholder': 'text-placeholder',
      'text-placeholder_subtle': 'text-placeholder_subtle',
      'text-primary': 'text-primary',
      'text-primary_on-brand': 'text-primary_on-brand',
      'text-quaternary': 'text-quaternary',
      'text-quaternary_on-brand': 'text-quaternary_on-brand',
      'text-secondary': 'text-secondary',
      'text-secondary_hover': 'text-secondary_hover',
      'text-secondary_on-brand': 'text-secondary_on-brand',
      'text-success-primary': 'text-success-primary',
      'text-success-secondary': 'text-success-secondary',
      'text-tertiary': 'text-tertiary',
      'text-tertiary_hover': 'text-tertiary_hover',
      'text-tertiary_on-brand': 'text-tertiary_on-brand',
      'text-warning-primary': 'text-warning-primary',
      'text-warning-secondary': 'text-warning-secondary',
      'text-white': 'text-white',
    } satisfies Record<TypographyColors, string>,
  },
});

// sometimes in safari line-clamp doesn't work, so we need to use max-height
// max-height = line-height * number-of-lines
// ref: https://github.com/CezaryDanielNowak/React-dotdotdot/blob/master/src/clamp.js
// 1rem is a fallback if --typography-line-height isn't set
// --typography-line-height is set in packages/style/src/tailwind_extension.css
export const truncateStyle = ({
  maxNumberOfLines,
  minNumberOfLines,
}: {
  maxNumberOfLines?: number;
  minNumberOfLines?: number;
}): CSSProperties | undefined => {
  if (!minNumberOfLines && !maxNumberOfLines) {
    return undefined;
  }

  let styles = {};

  if (minNumberOfLines) {
    styles = {
      minHeight: `calc(var(--typography-line-height, 1rem) * ${minNumberOfLines})`,
    };
  }

  return !maxNumberOfLines || maxNumberOfLines <= 0
    ? styles
    : {
        ...styles,
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        overflow: 'hidden',
        maxHeight: `calc(var(--typography-line-height, 1rem) * ${maxNumberOfLines})`,
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxNumberOfLines,
      };
};
