import type { AfterResponseHook } from 'ky';
import { HTTP_HEADERS, REDIRECT_TO_KEY } from '../../../../auth/const';
import { isServer } from '../../utils';
import type { HookGetterArgs } from '../types';
import isMobile from '../../../native/isMobile';

export const handleExpiredPassword = ({ options }: HookGetterArgs): AfterResponseHook => {
  const handleExpiredPasswordHook: AfterResponseHook = (req, opts, response) => {
    if (
      !isServer() &&
      !isMobile() &&
      // disable auto redirect for cors domain
      !options.credentials &&
      response.headers.get(HTTP_HEADERS.ExpiredPassword) === 'true' &&
      // if the pathname is already update-password, don't redirect
      !window.location.pathname.includes('/update-password')
    ) {
      const currentPath = window.location.pathname + window.location.search;
      const redirectURL = new URL('/update-password', window.location.origin);
      redirectURL.searchParams.set(REDIRECT_TO_KEY, currentPath);
      window.location.href = redirectURL.toString();
    }
  };
  return handleExpiredPasswordHook;
};
