import { cva } from 'class-variance-authority';

export const chipVariants = cva(
  ['flex flex-row shrink-0 items-center rounded-full gap-x-xs min-h-0 h-6'],
  {
    variants: {
      variant: {
        outlined: 'bg-primary border border-primary hover:bg-secondary_hover',
        soft: '',
        solid: '',
      },
    },
  },
);
