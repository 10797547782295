'use client';
import { ThemeOverrideContextProvider } from '@unifyapps/ui/providers/ThemeOverrideContext';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import useFetchAuthInterfaceResources from '../../hooks/fetchAndInvalidation/fetch/useFetchAuthInterfaceResources';
import { NoCodeTemplates } from '../../const/noCodeTemplates';
import type { InterfaceComponentPage } from '../../types/interfaceComponent';
import LoginRenderer from '../LoginRenderer';
import { InterfaceProvider } from '../InterfaceProvider';
import { InterfacePage } from '../InterfacePage';
import type BlocksRegistry from '../RegistryProvider/BlocksRegistry';
import { getFallbackLoginPage } from './fallbackLoginPage';

type GovernancePageProps = {
  interfaceComponent: InterfaceComponentPage;
  interfaceRecord: InterfaceEntity;
  searchParams?: {
    returnTo?: string;
  };
  registry: BlocksRegistry;
};

const allFallbackPages = new Set([
  NoCodeTemplates.login.interfacePageId,
  NoCodeTemplates.publicLogin.interfacePageId,
]);

function GovernancePage(props: GovernancePageProps) {
  const { interfaceRecord, interfaceComponent, searchParams, registry } = props;

  // in case of login page we want to show fallback if any fetching error occurs
  const renderFallback = allFallbackPages.has(interfaceComponent.id ?? '')
    ? () => (
        <LoginRenderer
          interfaceRecord={interfaceRecord}
          page={getFallbackLoginPage(
            NoCodeTemplates.publicLogin.interfacePageId === interfaceComponent.id,
          )}
          registry={registry}
        />
      )
    : undefined;

  return (
    <InterfaceProvider
      interfaceComponent={interfaceComponent}
      interfaceSlug={interfaceRecord.id}
      pageInputs={searchParams}
      registry={registry}
      renderFallback={renderFallback}
      useFetchResources={useFetchAuthInterfaceResources}
    >
      <InterfacePage />
    </InterfaceProvider>
  );
}

function ThemedGovernancePage(props: GovernancePageProps) {
  const { interfaceRecord } = props;
  const style = interfaceRecord.properties.theme?.output;

  return (
    <ThemeOverrideContextProvider value={style}>
      <GovernancePage {...props} />
    </ThemeOverrideContextProvider>
  );
}

export { GovernancePage, ThemedGovernancePage };
