import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUnifyAi3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M3.315.833a.5.5 0 0 1 .5.5V2.5h1.167a.5.5 0 0 1 0 1H3.815v1.167a.5.5 0 1 1-1 0V3.5H1.648a.5.5 0 1 1 0-1h1.167V1.334a.5.5 0 0 1 .5-.5"
    />
    <path
      fill="url(#b)"
      d="M7.982 1.5a.5.5 0 0 1 .466.32l1.157 3.007c.197.512.267.686.369.828q.146.207.353.353c.142.101.315.172.827.369h.001l2.461.926a.5.5 0 1 1-.352.936l-2.468-.928-.057-.022c-.434-.167-.734-.282-.992-.466a2.5 2.5 0 0 1-.588-.588c-.184-.258-.3-.558-.466-.992l-.711-1.85-.712 1.85c-.166.434-.282.734-.465.992a2.5 2.5 0 0 1-.589.588c-.258.184-.558.299-.992.466L3.374 8l1.85.712c.434.166.734.282.992.465a2.5 2.5 0 0 1 .589.589c.183.258.299.558.465.992l.021.053.905 2.253a.5.5 0 1 1-.928.373l-.91-2.263c-.196-.513-.267-.687-.368-.829a1.5 1.5 0 0 0-.353-.353c-.142-.101-.316-.172-.829-.369L1.802 8.467a.5.5 0 0 1 0-.934l3.006-1.156c.513-.197.687-.268.829-.369a1.5 1.5 0 0 0 .353-.353c.101-.142.172-.316.369-.828L7.515 1.82a.5.5 0 0 1 .467-.32"
    />
    <path
      fill="url(#c)"
      fillRule="evenodd"
      d="M10.19 13.923H8.983l1.57-4.654h1.497l1.57 4.653h-1.208l-.307-.977h-1.607zm1.128-3.482.518 1.65h-1.072l.517-1.65z"
      clipRule="evenodd"
    />
    <path fill="url(#d)" d="M15.19 9.269v4.653h-1.125V9.27z" />
    <path
      fill="url(#e)"
      d="M3.815 11.334a.5.5 0 0 0-1 0V12.5H1.648a.5.5 0 1 0 0 1h1.167v1.167a.5.5 0 1 0 1 0V13.5h1.167a.5.5 0 0 0 0-1H3.815z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={1.148}
        x2={15.19}
        y1={6.541}
        y2={6.554}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F49E7" />
        <stop offset={1} stopColor="#F67979" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={1.148}
        x2={15.19}
        y1={6.541}
        y2={6.554}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F49E7" />
        <stop offset={1} stopColor="#F67979" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={1.148}
        x2={15.19}
        y1={6.541}
        y2={6.554}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F49E7" />
        <stop offset={1} stopColor="#F67979" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={1.148}
        x2={15.19}
        y1={6.541}
        y2={6.554}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F49E7" />
        <stop offset={1} stopColor="#F67979" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={1.148}
        x2={5.482}
        y1={12.723}
        y2={12.727}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5F49E7" />
        <stop offset={1} stopColor="#F67979" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgUnifyAi3;
