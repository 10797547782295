import { cva } from 'class-variance-authority';

export const inputSizeVariants = cva('', {
  variants: {
    size: {
      sm: 'max-h-7 text-xs',
      // TODO: proper way is to introduce lg level input in DS
      md: 'max-h-9 text-md md:text-sm',
    },
  },
});

export const INPUT_VARS_CLASSNAME = 'ua-input-vars';
