'use client';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { getPageLayout } from '../InterfacePage';
import { InterfaceResourceProvider } from '../InterfaceResourceProvider';
import type BlocksRegistry from '../RegistryProvider/BlocksRegistry';
import { useRegistryContext } from '../RegistryProvider';

function LoginRenderer({ page }: { page: InterfacePageEntity }) {
  const { body } = getPageLayout(page.properties.layout);
  const BlockRenderer = useRegistryContext().registry.getBlockRenderer();

  return <>{body ? <BlockRenderer blockId={body} key={body} /> : null}</>;
}

function LoginRendererWrapper({
  page,
  registry,
  interfaceRecord,
}: {
  page: InterfacePageEntity;
  interfaceRecord: InterfaceEntity;
  registry: BlocksRegistry;
}) {
  return (
    <InterfaceResourceProvider interfaceRecord={interfaceRecord} page={page} registry={registry}>
      <LoginRenderer page={page} />
    </InterfaceResourceProvider>
  );
}

export default LoginRendererWrapper;
