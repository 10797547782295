import type { ErrorPayload } from '../../consts/error';

export class UAFetchError<T = Record<string, unknown>> extends Error {
  payload: ErrorPayload<T> | undefined;
  status: number;
  statusText: string;
  url: string;

  constructor({
    message,
    payload,
    status,
    statusText,
    url,
  }: {
    message: string;
    payload?: ErrorPayload<T>;
    url: string;
    status: number;
    statusText: string;
  }) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.url = url;
    this.message = message;
    this.payload = payload;
    this.name = 'UAFetchError';
  }
}
