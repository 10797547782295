export enum DeviceVariantType {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export type UseScreenReturnType = {
  screen: DeviceVariantType;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
};
