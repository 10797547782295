import _isString from 'lodash/isString';

const ENTITY_MAP = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
  '&#x2F;': '/',
} as Record<string, string>;

export const decode18NInterpolatedString = (htmlString: string): string =>
  _isString(htmlString)
    ? htmlString.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&#x2F;/g, (s) => {
        return ENTITY_MAP[s];
      })
    : htmlString;
