import type { AfterResponseHook } from 'ky';
import { isServer } from '../../utils';
import isMobile from '../../../native/isMobile';
import { AUTH_ENDPOINT_KEY, LOGOUT_URL } from '../../../../auth/const';
import type { HookGetterArgs } from '../types';

const getPathNameFromUrl = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.pathname;
};

export const handleLogout = ({ options }: HookGetterArgs): AfterResponseHook => {
  const handleLogoutHook: AfterResponseHook = (req, opts, response) => {
    if (
      response.status === 401 &&
      // req path doesn't start with /auth
      !getPathNameFromUrl(req.url).startsWith(AUTH_ENDPOINT_KEY) &&
      !isServer() &&
      !isMobile() &&
      // disable auto redirect for cors domain
      !options.credentials
    ) {
      // redirect to login page
      window.location.href = LOGOUT_URL;
    }
    if (response.ok && response.redirected) {
      // Extract the Location header from the response
      // const redirectUrl = response.headers.get('Location');
      // Manually follow the redirect
      window.location.href = response.url;
    }
  };
  return handleLogoutHook;
};
