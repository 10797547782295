import type { BeforeRequestHook } from 'ky';
import { isServer } from '../../utils';
import type { HookGetterArgs } from '../types';

export const getServerAuthHeaders = ({
  fetchArgs: { url, method, data },
  baseURL,
}: HookGetterArgs): BeforeRequestHook => {
  const authHeadersHook: BeforeRequestHook = async (request) => {
    if (isServer() && url.startsWith('/')) {
      const { headers: nextHeaders } = await import('next/headers');
      const header = nextHeaders();
      const reqCookie = header.get('cookie') ?? '';

      if (reqCookie) {
        request.headers.set('cookie', reqCookie);
      }
    }
  };
  return authHeadersHook;
};
