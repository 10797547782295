import { cva } from 'class-variance-authority';

// keeping empty strings here, so styles can be easily added without modifying any other file
export const checkboxRootVariants = cva('gap-md', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    indeterminate: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: 'cursor-not-allowed',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    indeterminate: false,
    disabled: false,
    focusVisible: false,
  },
});

export const checkboxCheckboxVariants = cva(
  [
    'isolate border shrink-0',
    '[&>svg]:absolute', // fixes jumping checkbox, when check icon is mounted
  ],
  {
    variants: {
      checked: {
        false: '',
        true: '',
      },
      indeterminate: {
        false: '',
        true: '',
      },
      disabled: {
        false: '',
        true: '',
      },
      focusVisible: {
        false: '',
        true: '',
      },
      size: {
        sm: 'size-[16px] rounded-xs',
        md: 'size-[20px] rounded-sm',
      },
    },
    compoundVariants: [
      // colors, using important because mui has styles for many combinations and their selectors are complex
      {
        // [ ]
        disabled: false,
        checked: false,
        indeterminate: false,
        className: '!border-primary !bg-primary hover:!bg-primary_hover',
      },
      {
        // [x]
        disabled: false,
        checked: true,
        indeterminate: false,
        className: '!bg-brand-solid !border-brand-solid !text-fg-white hover:!bg-brand-solid_hover',
      },
      {
        // [-]
        disabled: false,
        indeterminate: true,
        className:
          '!border-brand-solid !bg-primary !text-fg-brand-primary hover:!bg-brand-primary_hover',
      },
      // disabled styles
      {
        disabled: true,
        checked: true,
        className: '!bg-disabled_subtle !border-disabled !text-fg-disabled_subtle opacity-50',
      },
      {
        disabled: true,
        checked: false,
        className: '!border-disabled !bg-disabled_subtle !text-fg-disabled_subtle',
      },
      // focus rings
      {
        // [ ]
        focusVisible: true,
        checked: false,
        indeterminate: false,
        className: 'ring-gray',
      },
      {
        // [x]
        focusVisible: true,
        checked: true,
        indeterminate: false,
        className: 'ring-brand',
      },
      {
        // [-]
        focusVisible: true,
        indeterminate: true,
        className: 'ring-brand',
      },
    ],
    defaultVariants: {
      checked: false,
      indeterminate: false,
      disabled: false,
      focusVisible: false,
    },
  },
);

// keeping empty strings here, so styles can be easily added without modifying any other file
export const checkboxActionVariants = cva('', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    indeterminate: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    indeterminate: false,
    disabled: false,
    focusVisible: false,
  },
});

// keeping empty strings here, so styles can be easily added without modifying any other file
export const checkboxLabelVariants = cva('text-sm font-medium text-secondary', {
  variants: {
    checked: {
      false: '',
      true: '',
    },
    indeterminate: {
      false: '',
      true: '',
    },
    disabled: {
      false: '',
      true: '',
    },
    focusVisible: {
      false: '',
      true: '',
    },
    size: {
      sm: '',
      md: '',
    },
  },
  defaultVariants: {
    checked: false,
    indeterminate: false,
    disabled: false,
    focusVisible: false,
  },
});
