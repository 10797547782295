'use client';
import { clsx } from 'clsx';
import React from 'react';
import { useWindowSize } from 'react-use';
import { UA_VARIABLES } from '@unifyapps/style/themes/consts';
import useScreen from '@unifyapps/hooks/useScreen';
import BaseSnackbar from '../../_components/Snackbar';
import { Alert } from '../Alert';
import type { SnackbarProps } from './types';

const DESKTOP_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'right' } as const;
const NON_DESKTOP_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'center' } as const;

export function Snackbar(
  props: SnackbarProps & {
    open: boolean;
    onClose: () => void;
  },
) {
  const { width: windowWidth } = useWindowSize();
  const { isDesktop } = useScreen();

  const { anchorOrigin, className, open, color, autoHideDuration, onClose, ...alertProps } = props;
  const anchor = anchorOrigin || (isDesktop ? DESKTOP_ANCHOR_ORIGIN : NON_DESKTOP_ANCHOR_ORIGIN);

  return (
    <BaseSnackbar
      anchorOrigin={anchor}
      autoHideDuration={autoHideDuration ?? (color === 'warning' ? 5000 : 3000)}
      className={clsx(
        'p-0',
        {
          'mt-topbarHeight': anchor.vertical === 'top', // NOTE: if the snackbar is show at top, we need to add margin to show snackbar below the topbar
        },
        className,
        UA_VARIABLES,
      )}
      onClose={onClose}
      open={open}
      style={{ maxWidth: Math.max(windowWidth * 0.618 * 0.382, 500) }} // NOTE: calculated using golden ratio of the screen width
    >
      <Alert
        className="h-full w-full"
        color={color}
        direction={alertProps.description || alertProps.bottomDecoratorNode ? 'col' : 'row'}
        onClose={onClose}
        {...alertProps}
      />
    </BaseSnackbar>
  );
}
