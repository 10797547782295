// These are just placeholder functions for React web
const noOp = () => null;

export const getDomain = () => '';
export const setDomain = (domain: string) => noOp();

export const getCookie = () => '';
export const setCookie = (cookie: string) => noOp();

export const getInterfaceId = () => '';
export const setInterfaceId = (interfaceId: string) => noOp();

export const clearNativeCookies = noOp;
