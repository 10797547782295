import BaseAutocompleteOption from '@mui/joy/AutocompleteOption';
import type { AutocompleteRenderOptionState } from '@mui/joy/Autocomplete';
import type { AutocompleteFreeSoloValueMapping } from '@mui/base/useAutocomplete';
import { memo, type ReactNode } from 'react';
import { clsx } from 'clsx';
import Stack from '../../_components/Stack';
import { Typography } from '../Typography';
import { Checkbox } from '../Checkbox';
import { Box } from '../Box';
import { autocompleteOptionVariants } from './styles';
import type { AutocompleteOptionProps, AutocompleteSizes } from './types';

interface OptionProps<T, FreeSolo extends boolean | undefined> {
  size: AutocompleteSizes;
  option: T;
  state: AutocompleteRenderOptionState;
  renderOptionLabel?: React.FC<{ option: T; state: AutocompleteRenderOptionState }>;
  getOptionLabel: (option: T | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
  getOptionDescription?: (
    option: T | AutocompleteFreeSoloValueMapping<FreeSolo>,
  ) => string | undefined;
  startDecoratorComponent?: ReactNode;
}

function AutocompleteLabel<T, FreeSolo extends boolean | undefined>({
  option,
  state,
  renderOptionLabel,
  getOptionLabel,
  size,
}: OptionProps<T, FreeSolo>) {
  if (renderOptionLabel) {
    return renderOptionLabel({ option, state });
  }

  return (
    <Typography className="truncate" variant={size === 'sm' ? 'text-xs' : 'text-sm'}>
      {getOptionLabel(option)}
    </Typography>
  );
}

function AutocompleteMultipleOption<T, FreeSolo extends boolean | undefined>({
  renderOptionLabel,
  getOptionLabel,
  state,
  size,
  option,
  startDecoratorComponent,
}: OptionProps<T, FreeSolo>) {
  return (
    <Stack alignItems="center" className="gap-x-md" direction="row">
      <Box className="h-4 w-4">
        <Checkbox checked={state.selected} />
      </Box>
      {startDecoratorComponent}
      <AutocompleteLabel
        getOptionLabel={getOptionLabel}
        option={option}
        renderOptionLabel={renderOptionLabel}
        size={size}
        state={state}
      />
    </Stack>
  );
}

function AutocompleteSingleOption<T, FreeSolo extends boolean | undefined>({
  renderOptionLabel,
  getOptionDescription,
  getOptionLabel,
  state,
  option,
  startDecoratorComponent,
  size,
}: OptionProps<T, FreeSolo>) {
  const description = getOptionDescription?.(option);

  return (
    <Stack className="w-full" direction="column">
      <Stack alignItems="center" className="gap-x-md flex-1" direction="row">
        {startDecoratorComponent}
        <AutocompleteLabel
          getOptionLabel={getOptionLabel}
          option={option}
          renderOptionLabel={renderOptionLabel}
          size={size}
          state={state}
        />
      </Stack>
      {description ? (
        <Typography color="text-quaternary" variant="text-xxxs">
          {description}
        </Typography>
      ) : null}
    </Stack>
  );
}

function AutocompleteOption<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  multiple,
  size = 'md',
  optionProps,
  className,
  ...rest
}: AutocompleteOptionProps<T, Multiple, DisableClearable, FreeSolo> & {
  startDecoratorComponent?: ReactNode;
  className?: string;
}) {
  return (
    <BaseAutocompleteOption
      {...optionProps}
      className={clsx(
        className,
        autocompleteOptionVariants({
          size,
          selected: rest.state.selected,
        }),
      )}
      key={optionProps.key}
    >
      {multiple ? (
        <AutocompleteMultipleOption size={size} {...rest} />
      ) : (
        <AutocompleteSingleOption size={size} {...rest} />
      )}
    </BaseAutocompleteOption>
  );
}

export { BaseAutocompleteOption };
export default memo(AutocompleteOption) as typeof AutocompleteOption;
