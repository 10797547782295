'use client';

import { default as BaseInput } from '@mui/joy/Input';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { useMount } from 'react-use';
import { clsx } from 'clsx';
import { slowCn } from '../../lib/utils';
import { getInputState } from '../../lib/getInputState';
import { INPUT_VARS_CLASSNAME, inputSizeVariants } from './styles';
import type { InputProps } from './types';
import './input.vars.css';
import './input.css';

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    rootClassName,
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorComponent: EndDecoratorComponent,
    endDecoratorNode,
    size = 'md',
    slotProps,
    error,
    onChange,
    onChangeValue,
    autoFocus,
    variant = 'outlined',
    sx,
    ...rest
  } = props;

  const state = getInputState(props);
  const inputRef = useRef<HTMLInputElement>();
  const forkedRef = useForkRef(inputRef, ref);

  useMount(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent
      className="text-quaternary"
      height={size === 'sm' ? 14 : 16}
      width={size === 'sm' ? 14 : 16}
    />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent
      className={clsx({
        'text-error-primary': state === 'error',
        'text-disabled': state === 'disabled' || state === 'readonly',
        'text-quaternary': state === 'default',
      })}
      height={size === 'sm' ? 14 : 16}
      width={size === 'sm' ? 14 : 16}
    />
  ) : (
    endDecoratorNode
  );

  const _rootClassName = slowCn(INPUT_VARS_CLASSNAME, inputSizeVariants({ size }), rootClassName);

  const slotPropsWithRef = useMemo(
    () => ({
      ...slotProps,
      root: {
        className: _rootClassName,
        ...slotProps?.root,
      },
      input: {
        ...slotProps?.input,
        ref: forkedRef,
      },
      startDecorator: {
        className: 'shrink-0',
        ...slotProps?.startDecorator,
      },
      endDecorator: {
        className: 'shrink-0',
        ...slotProps?.endDecorator,
      },
    }),
    [slotProps, _rootClassName, forkedRef],
  );

  const onChangeHandler = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (onChangeValue) onChangeValue(e.target.value);
      if (onChange) onChange(e);
    },
    [onChange, onChangeValue],
  );

  useEffect(() => {
    if (inputRef.current && inputRef.current.value !== rest.value) {
      inputRef.current.value = (rest.value ?? '') as string;
    }
  }, [rest.value]);

  return (
    <BaseInput
      {...rest}
      className={className}
      data-size={size}
      data-state={state}
      data-variant={variant}
      endDecorator={endDecorator}
      error={error}
      onChange={onChangeHandler}
      slotProps={slotPropsWithRef}
      startDecorator={startDecorator}
      sx={sx}
    />
  );
});

Input.displayName = 'Input';

export default Input;
