'use client';
import { createContext, useContext } from 'react';

export type OnErrorType = (error: unknown) => void;

export const OnErrorContext = createContext<{
  onError?: OnErrorType;
}>({});

export const useOnErrorContext = () => {
  const context = useContext(OnErrorContext);
  return context;
};
