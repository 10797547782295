import { clsx } from 'clsx';
import { Typography } from '../Typography';
import { Button } from '../Button';
import Stack from '../../_components/Stack';
import { FeaturedIcon } from '../FeaturedIcon';
import type { EmptyStateProps } from './types';
import { containerVariants, descriptionVariants, titleVariants } from './styles';

function EmptyState(props: EmptyStateProps) {
  const {
    title,
    description,
    Icon,
    icon,
    size = 'sm',
    variant = 'hug',
    className,
    primaryButton,
    secondaryButton,
    slotProps,
  } = props;

  const containerClassName = containerVariants({ className, size, variant });
  const titleClassName = titleVariants({ size });
  const descriptionClassName = descriptionVariants({ size });

  // combine the check?
  const featuredIconSize = size === 'sm' ? 'lg' : 'xl';
  const titleVariant = size === 'sm' ? 'text-md' : 'text-xl';
  const descriptionVariant = size === 'sm' ? 'text-sm' : 'text-md';
  const buttonSize = size === 'sm' ? 'md' : 'lg';

  const { label: primaryLabel, ...primaryRest } = primaryButton ?? {};
  const { label: secondaryLabel, ...secondaryRest } = secondaryButton ?? {};

  return (
    <Stack
      alignItems="center"
      className={containerClassName}
      direction="column"
      justifyContent="center"
    >
      {icon ??
        (Icon ? (
          <FeaturedIcon Icon={Icon} size={featuredIconSize} {...slotProps?.featuredIcon} />
        ) : null)}
      <Typography
        className={clsx(titleClassName, slotProps?.title?.className)}
        color="text-primary"
        variant={titleVariant}
        weight="semi-bold"
      >
        {title}
      </Typography>
      {description ? (
        <Typography
          className={clsx(descriptionClassName, slotProps?.description?.className)}
          color="text-tertiary"
          variant={descriptionVariant}
          weight="regular"
        >
          {description}
        </Typography>
      ) : null}
      {primaryButton || secondaryButton ? (
        <Stack className="gap-x-lg" direction="row" justifyContent="center">
          {secondaryButton ? (
            <Button color="neutral" variant="outline" {...secondaryRest} size={buttonSize}>
              {secondaryLabel}
            </Button>
          ) : null}
          {primaryButton ? (
            <Button color="brand" variant="solid" {...primaryRest} size={buttonSize}>
              {primaryLabel}
            </Button>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
}

export default EmptyState;
