import { createContext, useContext } from 'react';

// To override the default context of popover which is document.body by default,
// but we need to override it for some provider where our app is just a part of the page
const PopoverGlobalContainerContext = createContext<HTMLElement | null>(null);

const usePopoverGlobalAnchorContext = () => {
  return useContext(PopoverGlobalContainerContext);
};

export { usePopoverGlobalAnchorContext };
export default PopoverGlobalContainerContext;
