import type { AfterResponseHook } from 'ky';
import { HTTP_HEADERS } from '../../../../auth/const';
import { isServer } from '../../utils';
import type { HookGetterArgs } from '../types';

export const handleLogging = ({
  fetchArgs: { url, method, data },
  baseURL,
}: HookGetterArgs): AfterResponseHook => {
  const handleLoggingHook: AfterResponseHook = (request, opts, response) => {
    if (isServer()) {
      const endTime = new Date().toISOString();
      const startTime = request.headers.get(HTTP_HEADERS.Timestamp);
      // set -1 in case startTime is not available, rare scenarios but safe handling
      const duration = startTime ? new Date(endTime).getTime() - new Date(startTime).getTime() : -1;

      console.debug(`[${method}] ${baseURL} end`, {
        data,
        params: {
          url: baseURL,
          traceId: request.headers.get(HTTP_HEADERS.TraceId),
          timestamp: endTime,
          duration,
        },
      });
    }
  };

  return handleLoggingHook;
};
