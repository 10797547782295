import { cva } from 'class-variance-authority';

export const alertVariants = cva('gap-xl p-xl border rounded-xs shadow-md', {
  variants: {
    color: {
      brand: 'bg-brand-primary text-fg-brand-primary border-brand',
      success: 'bg-success-primary text-fg-success-primary border-utility-success-300',
      warning: 'bg-warning-primary text-fg-warning-primary border-utility-warning-300',
      error: 'bg-error-primary text-fg-error-primary border-error',
      default: 'bg-primary_alt text-fg-tertiary border-primary',
    },
    direction: {
      row: '',
      col: 'items-start',
    },
  },
});

export const containerVariants = cva('flex flex-1 justify-between', {
  variants: {
    direction: {
      row: 'flex-row items-center',
      col: 'flex-col gap-y-lg',
    },
  },
});

export const infoVariants = cva('flex flex-1', {
  variants: {
    direction: {
      row: 'flex-row gap-x-sm me-lg',
      col: 'flex-col gap-y-1',
    },
  },
});
