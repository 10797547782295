import { forwardRef } from 'react';
import type { BoxProps } from './types';

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { as: Component = 'div', ...rest } = props;

  return <Component {...rest} ref={ref} />;
});

Box.displayName = 'Box';

export default Box;
