'use client';

import type React from 'react';
import i18next from 'i18next';
import type { UseTranslationOptions } from 'react-i18next';
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useCallback } from 'react';
import acceptLanguage from 'accept-language';
import type { TFunction, TOptions } from 'i18next';
import { HTTP_HEADERS } from '@unifyapps/network/auth/const';
import { getOptions, languages } from './settings';

export type { TFunction } from 'i18next';

export { Trans } from 'react-i18next';

const isServer = typeof window === 'undefined';

// Initialize i18next with React integration and backend resources
i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      // Dynamically import locale files based on language and namespace
      (language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    ...getOptions(),
    lng: isServer ? undefined : document.documentElement.lang, // Detect language on client side
    preload: isServer ? languages : [],
  })
  .catch((err) => {
    console.error('i18next init', err);
  });

/**
 * Custom hook for translation with server-side support
 * @param ns - Namespace for the translations
 * @param options - Options for the hook
 */
export function useTranslation(
  ns?: string | string[],
  options?: UseTranslationOptions<undefined> | undefined,
) {
  let lng: string | undefined;
  if (isServer) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment -- safe
    const headers = require('next/headers').headers; // Detect language on server side
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- safe
    lng =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument -- safe
      headers().get(HTTP_HEADERS.Locale) || acceptLanguage.get(headers().get('Accept-Language'));
  } else {
    // lng = document.documentElement.lang; // Detect language on client side, for !isServer code, we're not using lng from here
  }

  const value = useTranslationOrg(ns); // Original translation hook
  const t: TFunction = value.t;

  // Custom translation function to handle rendering issues.
  // The original useTranslationOrg function has some issues, so we override it.
  // If running on the server, include the language (lng) in the options.
  // on client side, we are assigning in i18n initialization above so we can use it directly here.
  const updatedT: TFunction = useCallback(
    (key: string, _options?: TOptions) => {
      if (isServer) {
        return t(key, {
          ..._options,
          lng,
        });
      }
      return t(key, _options);
    },
    [t, lng],
  ) as TFunction;

  return {
    ...value,
    t: updatedT, // Use updated translation function
  };
}

export function useChangeLanguage() {
  const { i18n } = useTranslationOrg();

  const setLanguage = useCallback(
    (lng: string) => {
      try {
        void i18n.changeLanguage(lng);
      } catch (error) {
        console.error('Failed to change language', error);
      }
    },
    [i18n],
  );

  return setLanguage;
}

export function LanguageChangeProvider({ children }: { children: React.ReactNode }) {
  // 'use client';
  // const { i18n } = useTranslationOrg();
  //
  // useKey(
  //   (event) => {
  //     return event.ctrlKey && event.key === 'L';
  //   },
  //   () => {
  //     i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en').catch((err) => {
  //       console.error('ChangeLanguage', err);
  //     });
  //   },
  // );

  return children;
}
