import { getDomain } from '../native/localStorage/localStorage';
import isMobile from '../native/isMobile';
import type { FetchArgs, FetchOptions } from './types';

export const isServer = () => typeof window === 'undefined';

export async function getResponseData(response: Response) {
  const responseData = await response.text();
  try {
    return JSON.parse(responseData) as object;
  } catch (e) {
    return responseData;
  }
}

export async function getBaseURL({ url }: Pick<FetchArgs, 'url'>) {
  let baseURL = ''; // will be empty for client to use relative url

  // for server get host from headers and url doesn't have host
  if (isServer() && url.startsWith('/')) {
    const { headers: nextHeaders } = await import('next/headers');
    const header = nextHeaders();

    // set header for server side rendering
    const host = header.get('host') ?? '';
    const protocol = header.get('x-forwarded-proto') ?? 'http';
    baseURL = `${protocol}://${host}`;
  } else if (isMobile()) {
    baseURL = getDomain();
  } else if (!isServer() && !isMobile() && '__unifyBaseUrl' in window) {
    baseURL = window.__unifyBaseUrl as string;
  }

  return `${baseURL}${url}`;
}

export function getQueryParams(
  { data, params }: Pick<FetchArgs, 'data' | 'params'>,
  options: FetchOptions,
) {
  const debugParams = options.debugParams ?? ({} as Record<string, string>);
  if (data && 'lookupType' in data && 'type' in data && typeof data.type === 'string') {
    debugParams[data.type] = data.lookupType as string;
  }
  let queryParams = new URLSearchParams({ ...params, ...debugParams }).toString();
  queryParams = queryParams ? `?${queryParams}` : '';
  return queryParams;
}
