import { merge as _merge } from 'lodash';
import type { CSSProperties } from 'react';
import { truncateStyle } from '../styles';

export function getTypographyStyle({
  maxNumberOfLines,
  minNumberOfLines,
  propStyle,
  styleOverride,
}: {
  maxNumberOfLines?: number;
  minNumberOfLines?: number;
  propStyle?: CSSProperties | undefined;
  styleOverride?: CSSProperties | undefined;
}) {
  const _style = _merge(truncateStyle({ maxNumberOfLines, minNumberOfLines }), propStyle);
  return styleOverride ? { ..._style, ...styleOverride } : _style;
}
