import type { QueryInputsType } from '@unifyapps/defs/types/dataSource';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { IDP_QUERY, PUBLIC_INTERFACE_IDP_QUERY } from '../LoginRenderer/request';

export const getFallbackLoginPage = (isPublic?: boolean): InterfacePageEntity => ({
  id: 'I6B7vlyH3051',
  entityType: 'e_component',
  deleted: false,
  version: 1,
  properties: {
    id: 'I6B7vlyH3051',
    pageTitle: 'Login',
    visibility: 'public',
    dataSources: {
      zcUXgzecrKc7: {
        type: 'QUERY_BY_UA',
        interfacePageId: 'I6B7vlyH3051',
        inputs: {
          payload: {
            operationId: 'auth',
            ...(isPublic ? PUBLIC_INTERFACE_IDP_QUERY : IDP_QUERY),
          },
        } as Record<string, unknown> & QueryInputsType,
      },
    },
    layout: {
      header: null,
      body: 'root_id',
      footer: null,
    },
    blocks: {
      root_id: {
        id: 'root_id',
        dataSourceIds: ['zcUXgzecrKc7'],
        component: {
          // @ts-expect-error -- We have but don't support in union
          componentType: 'LoginLayout',
          layoutType: 'centered-card',
          idps: '{{zcUXgzecrKc7.objects}}',
          // this is resolved from interfaceRecord
          headerLogo: {
            componentType: 'Image',
          },
          heading: {
            componentType: 'Typography',
            content: { type: 'PLAIN_TEXT', value: 'Log in to your account' },
            appearance: {
              variant: 'display-sm',
              align: 'center',
              styles: { color: 'text-primary' },
            },
          },
        },
        fallback: {
          componentType: 'Loader',
          content: {},
          appearance: {
            size: 'md',
          },
        },
      },
      continue_id: {
        id: 'continue_id',
        component: {
          componentType: 'Button',
          content: {
            value: 'Continue',
            type: 'submit',
          },
          appearance: {
            variant: 'solid',
            size: 'lg',
            styles: {
              margin: {
                t: 'mt-3xl',
              },
            },
            className: 'w-full',
          },
        },
      },
    },
  },
});
