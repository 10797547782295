/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */

export type LookupByQueryRequestType =
  (typeof LookupByQueryRequestType)[keyof typeof LookupByQueryRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LookupByQueryRequestType = {
  ByQuery: 'ByQuery',
} as const;
