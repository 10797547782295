import { cva } from 'class-variance-authority';

export const containerVariants = cva('mx-auto h-full', {
  variants: {
    size: {
      sm: '',
      lg: '',
    },
    variant: {
      fill: 'w-full',
      hug: '',
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      variant: 'fill',
      className: 'min-w-[17.5rem] max-w-[30rem]',
    },
    {
      size: 'sm',
      variant: 'hug',
      className: 'max-w-[17.5rem]',
    },
    {
      size: 'lg',
      variant: 'fill',
      className: 'min-w-[22.5rem] max-w-[33.75rem]',
    },
    {
      size: 'lg',
      variant: 'hug',
      className: 'max-w-[22.5rem]',
    },
  ],
});

export const titleVariants = cva('text-center', {
  variants: {
    size: {
      sm: 'mt-xl',
      lg: 'mt-2xl',
    },
  },
});

export const descriptionVariants = cva('text-center', {
  variants: {
    size: {
      sm: 'mt-xs mb-3xl',
      lg: 'mt-md mb-4xl',
    },
  },
});
