import { forwardRef } from 'react';
import { Box } from '../Box';
import { slowCn } from '../../lib/utils';
import { featuredIconIconVariants, featuredIconVariants } from './styles';
import type { FeatureIconProps } from './types';

const FeaturedIcon = forwardRef<HTMLDivElement, FeatureIconProps>(
  function FeaturedIcon(props, ref) {
    const {
      color = 'white',
      size = 'sm',
      Icon,
      alt,
      iconUrl,
      className,
      iconClassName,
      ariaLabel,
      IconNode,
      ...rest
    } = props;

    const featuredIconClassName = featuredIconVariants({ className, color, size });
    const iconDefaultClassName = featuredIconIconVariants({ color, size });

    return (
      <Box aria-label={ariaLabel} {...rest} className={featuredIconClassName} ref={ref}>
        {/* eslint-disable-next-line no-nested-ternary -- is a valid use case */}
        {Icon ? (
          <Icon className={slowCn(iconDefaultClassName, iconClassName)} />
        ) : IconNode ? (
          <Box className={slowCn(iconDefaultClassName, iconClassName)}>{IconNode}</Box>
        ) : (
          <img alt={alt} className={slowCn(iconDefaultClassName, iconClassName)} src={iconUrl} />
        )}
      </Box>
    );
  },
);

export default FeaturedIcon;
