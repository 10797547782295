import type { BeforeRequestHook } from 'ky';
import { nanoid } from 'nanoid';
import { HTTP_HEADERS } from '../../../../auth/const';
import type { HookGetterArgs } from '../types';
import { isServer } from '../../utils';

export const getMetadataHeaders = ({
  fetchArgs: { url, method, data },
  baseURL,
}: HookGetterArgs): BeforeRequestHook => {
  const metadataHeadersHook: BeforeRequestHook = (request) => {
    // s_ for requests sent from server, c_ for requests sent from client
    const uniqueId = isServer() ? `s_${nanoid()}` : `c_${nanoid()}`;
    const startTime = new Date().toISOString();

    request.headers.set(HTTP_HEADERS.TraceId, uniqueId);
    request.headers.set(HTTP_HEADERS.Timestamp, startTime);

    // dont log this in development, since we're now logging end time
    if (isServer() && process.env.NODE_ENV !== 'development') {
      console.debug(`[${method}] ${baseURL} start`, {
        data,
        params: {
          url: baseURL,
          traceId: uniqueId,
          timestamp: startTime,
        },
      });
    }
  };

  return metadataHeadersHook;
};
