import type { BeforeRequestHook } from 'ky';
import { isServer } from '../../utils';
import { addProjectHeader, extractProjectDetailsFromRoute } from '../../../projects';
import type { HookGetterArgs } from '../types';
import isMobile from '../../../native/isMobile';

export const getProjectHeaders = ({
  fetchArgs: { data, url },
}: HookGetterArgs): BeforeRequestHook => {
  const projectHeadersHook: BeforeRequestHook = async (request) => {
    if (isServer() && url.startsWith('/')) {
      const { headers: nextHeaders } = await import('next/headers');
      const header = nextHeaders();
      const { projectId } = extractProjectDetailsFromRoute(header.get('pathname') ?? '');
      addProjectHeader(request.headers, String(projectId));
    }

    if (!isServer() && !isMobile()) {
      const { projectId: extractedProjectId } = extractProjectDetailsFromRoute(
        window.location.pathname,
      );

      const projectId =
        data && 'projectId' in data && data.projectId ? Number(data.projectId) : extractedProjectId;

      addProjectHeader(request.headers, String(projectId)); // converting projectId to string as we have to set it in headers
    }
  };
  return projectHeadersHook;
};
