import type { LookupByQueryRequest } from '@unifyapps/network/generated/models/lookupByQueryRequest';

export const IDP_QUERY: LookupByQueryRequest = {
  fields: ['name'],
  type: 'ByQuery',
  lookupType: 'IDENTITY_PROVIDER',
  query: '',
};

export const PUBLIC_INTERFACE_IDP_QUERY: LookupByQueryRequest = {
  fields: ['name'],
  type: 'ByQuery',
  lookupType: 'IDENTITY_PROVIDER',
  query: '',
  filter: { field: 'external', values: [true], op: 'EQUAL' },
};
