import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

/**
 * https://github.com/dcastil/tailwind-merge/issues/326#issuecomment-1780721267
 */
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      // @ts-expect-error -- custom typography classes
      typography: [
        'display-2xl',
        'display-xl',
        'display-lg',
        'display-md',
        'display-sm',
        'display-xs',
        'text-xl',
        'text-lg',
        'text-md',
        'text-sm',
        'text-xs',
        'text-xxs',
        'text-xxxs',
        'text-code',
      ],
      'font-size': [{ text: ['xxs', 'xxxs'] }],
    },
  },
});

/**
 * This function is used to merge tailwind classes.
 * If you just want to concatenate classes, use clsx instead
 */
export function slowCn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}
