import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M7.625 6.667A6.04 6.04 0 0 1 13.667.625H27c.154 0 .302.061.412.17l10.793 10.793c.109.11.17.258.17.412v21.333a6.04 6.04 0 0 1-6.042 6.042H13.667a6.04 6.04 0 0 1-6.042-6.042z"
    />
    <rect width={27.939} height={15} y={16.667} fill="#E62E05" rx={1} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.333 20v8.333h1.762v-2.701h1.481q.96 0 1.636-.355a2.47 2.47 0 0 0 1.038-.988q.357-.635.358-1.465 0-.83-.354-1.465a2.45 2.45 0 0 0-1.018-.997Q7.57 20 6.621 20zm1.762 4.22h1.196q.51 0 .839-.175a1.16 1.16 0 0 0 .496-.493q.167-.317.167-.728 0-.415-.167-.724a1.1 1.1 0 0 0-.496-.485q-.334-.175-.847-.175H5.095zm8.611 4.113h-2.954V20h2.979q1.257 0 2.164.5.907.497 1.396 1.429.492.93.492 2.23 0 1.302-.492 2.238a3.4 3.4 0 0 1-1.404 1.436q-.91.5-2.18.5m-1.192-1.51h1.119q.78 0 1.314-.276a1.78 1.78 0 0 0 .806-.867q.273-.59.273-1.521 0-.925-.273-1.51a1.77 1.77 0 0 0-.802-.863q-.532-.276-1.314-.276h-1.123zm6.574 1.51V20h5.518v1.453H20.85v1.985h3.39v1.453h-3.39v3.442z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPdf;
